<template>
  <div class="fill-height">
    <v-fade-transition mode="out-in">
      <div :key="1" class="fill-height d-flex flex-column justify-center align-center"
           v-if="status != 'GPTDiagnosisReady'">
        <div class="fallowUpQuestionArea d-flex flex-column">
          <div class="headerBanner pt-2 px-5 d-flex flex-column justify-center">
            <div class="d-flex">
              <div>
                <img src="../../assets/opretaor.png" width="50px" alt="">
              </div>
              <div class="pt-1">
                <div class="nameGPT">
                  درمانگر بر پایه هوش‌مصنوعی
                </div>
                <div class="GPTstatus">
                  {{ loader ? 'Is typing' : 'Online' }}
                </div>
              </div>
            </div>
          </div>
          <div class="messagesArea pt-5 px-5 flex-grow-1" id="messagesArea">
            <v-fade-transition group>
              <div class="d-flex " v-for="(message , i) in messages" :key="i"
                   :class="message.sender == 'chatGpt' || message.sender == 'system' ? 'justify-end' : 'justify-start'">
                <div class="textMessages "
                     :class="message.sender == 'chatGpt' || message.sender == 'system' ? 'textMessagesNotMe' : 'textMessagesMe' ">
                  <template v-if="message.sender == 'system'">
                    <template v-if="message.content == 'loader'">
                      <img src="../../assets/loading5.gif" height="20px" width="100%" alt="">
                    </template>
                  </template>
                  <template v-else>
                    {{ message.content }}
                  </template>
                </div>
              </div>
            </v-fade-transition>
          </div>
          <div class="actionArea mb-4">
            <div class="mx-6" v-if="!$route.query.conversation_id">
              <v-form ref="ccForm" @submit.prevent="submitForm">
                <v-text-field :rules="requiredRules" v-model="ccText" placeholder="متن پیام شما...">
                  <template v-slot:append>
                    <v-btn @click="sendMessage" :disabled="loader" elevation="0" class="mb-1"
                           :color="ccText ? 'primary' : 'transparent'">
                      <v-icon style="transform: rotate(180deg)">mdi-send</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-form>
            </div>
            <div v-else class="text-center mt-5 mx-6">
              <v-btn color="primary" elevation="0" @click="showConversationResponse">
                نمایش تفسیر
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div :key="2" v-else>
        <DiagnosisTemplate :diagnosis="FinalDiagnosis"/>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import DiagnosisTemplate from "@/components/ChatGpt/DiagnosisTemplate";
import {mapGetters} from "vuex";

export default {
  components: {DiagnosisTemplate},
  data() {
    return {
      messages: [
        {
          sender: 'chatGpt',
          content: '             این ابزار هوش مصنوعی توسط سیمیاروم برای سلامت روان شما طراحی شده. شما فقط با توضیح مختصری از حالتون، می‌تونید چالش‌ها و راهکارهایی رو در این مورد دریافت کنید.',
        },
      ],
      ccText: null,
      loader: false,
      status: "waitingForCC",
      active_fallow_up_question: 0,
      follow_up_questions: [],
      answers_fallow_up_question: [],
      retryGetResult: 0,
      cc: null,
      FinalDiagnosis: null,
      conversation: null,
      // FinalDiagnosis: {
      //   "info": "",
      //   "advice": "ممکن است دلدرد شما ناشی از فشارهای روزمره و استرس باشد. برای مدیریت آن می‌توانید از روش‌های آرامش بخشی مانند تنفس عمیق، ورزش منظم و مراقبت از خودتان استفاده کنید.",
      //   "goals": [{
      //     "goal": "تمرین تنفس عمیق",
      //     "description": "روزانه چند دقیقه به تمرین تنفس عمیق اختصاص دهید تا به آرامش روحی و جسمی برسید."
      //   }, {
      //     "goal": "انجام ورزش منظم",
      //     "description": "هر روز به مدت حداقل 30 دقیقه ورزش کنید تا انرژی منفی را از بدن خارج کنید و روحیه خود را بهبود بخشید."
      //   }, {
      //     "goal": "مراقبت از خود",
      //     "description": "وقتی دلدرد دارید، به خودتان مراقبت کنید و فعالیت‌هایی که لذت می‌برید را انجام دهید تا احساس خوبی داشته باشید."
      //   }]
      // },
    }
  },
  computed: {
    ...mapGetters({
      'fallow_up_questions': 'getGPTFallow_up',
      'responseGPT': 'getGPTResponse',
    })
  },
  watch: {
    '$route'(){
      if (!this.$route.query.conversation_id){
        this.status = 'waitingForCC'
      }
    },
    responseGPT() {
      this.resetMessages()
      console.log(JSON.parse(this.fallow_up_questions.diagnosis))
      let questions = JSON.parse(this.responseGPT.compliant)
      this.FinalDiagnosis = JSON.parse(this.responseGPT.diagnosis)
      questions.forEach((item) => {
        if (item.question != 'what is your main problem') {
          this.messages.push({
            sender: 'chatGpt',
            content: item.question,
          },)
        }
        this.messages.push({
          sender: 'client',
          content: item.answer,
        },)
      })
      this.status = 'waitingForCC'
    }
  },
  methods: {
    showConversationResponse() {

      this.status = 'GPTDiagnosisReady'


    },
    submitForm() {

    },
    resetMessages() {
      this.messages = [
        {
          sender: 'chatGpt',
          content: '             این ابزار هوش مصنوعی توسط سیمیاروم برای سلامت روان شما طراحی شده. شما فقط با توضیح مختصری از حالتون، می‌تونید چالش‌ها و راهکارهایی رو در این مورد دریافت کنید.',
        }
      ]
    },
    pushMessage(sender, content) {
      this.messages.push({
        sender: sender,
        content: content
      },)
      this.scrollToDown()
    },
    scrollToDown() {
      this.$nextTick().then(() => {
        let messagesArea = document.getElementById('messagesArea');
        messagesArea.scrollTo({
          top: messagesArea.scrollHeight,
          behavior: 'smooth'
        });
      })
    },
    sendCCAndGetFallowUpQuestion() {
      this.setLoading(true)
      let text = this.ccText
      this.$store.dispatch('sendCCAndGetFallowUpQuestion', {
        need_conversation: true,
        text: this.ccText
      }).then((resp) => {
        this.follow_up_questions = resp.data.data.response.follow_up_questions ?? resp.data.data.response.follow_up_qustions
        this.diagnosis = resp.data.data.response.diagnosis
        this.conversation = resp.data.data.conversation
        this.token = resp.data.data.token
        this.cc = text
        this.askFollowUpQuestions()
      }).finally(() => {
        this.setLoading(false)
      })
    },
    askFollowUpQuestions() {
      this.status = 'answerFallowUpQuestion'
      this.$nextTick().then(() => {
        if (this.follow_up_questions && this.follow_up_questions[this.active_fallow_up_question] !== undefined) {
          this.pushMessage('chatGpt', this.follow_up_questions[this.active_fallow_up_question])
        } else {
          this.getResult()
        }
      })
    },
    preparePayload() {
      let temp = []
      temp.push({
        "question": "what is your main problem",
        "answer": this.cc,
      })
      this.follow_up_questions.forEach((item, index) => {
        temp.push({
          "question": item,
          "answer": this.answers_fallow_up_question[index],
        })
      })
      return temp
      // this.answers_fallow_up_question
    },
    getResult() {
      this.retryGetResult++;
      this.status = 'waitingForGPTDiagnosis'
      this.setLoading(true)
      let payload = this.preparePayload()
      console.log(payload)
      this.$store.dispatch('getResultGPT', {
        text: payload,
        token: this.token,
        conversation_id: this.conversation.id
      }).then((resp) => {
        this.FinalDiagnosis = resp.data.data.response
        this.status = 'GPTDiagnosisReady'
        this.$emitEvent('getConversations')
        this.setLoading(false)
      }).catch((er) => {
        if (this.retryGetResult <= 1) {
          this.setLoading(false)
          this.getResult();
        } else {
          this.processFailedBackToCC()
        }
      })
    },
    processFailedBackToCC() {
      this.active_fallow_up_question = 0
      this.status = 'waitingForCC'
      this.setLoading(false)
      setTimeout(() => {
        this.pushMessage('chatGpt', 'فرایند بررسی شرح حال شما با مشکل مواجه شد. لطفا یک بار دیگر شرح حال خود را وارد کنید.')
        this.status = 'waitingForCC'
      }, 500)
    },
    sendMessage() {
      if (this.$refs.ccForm.validate()) {
        if (this.status == 'waitingForCC') {
          this.pushMessage('client', this.ccText)
          this.sendCCAndGetFallowUpQuestion()
          this.$refs.ccForm.reset()
        } else if (this.status == 'answerFallowUpQuestion') {
          this.answerFallowUpQuestion()
        }
      }
    },
    answerFallowUpQuestion() {
      this.pushMessage('client', this.ccText)
      this.active_fallow_up_question++;
      this.answers_fallow_up_question.push(this.ccText)
      this.$refs.ccForm.reset()
      setTimeout(() => {
        this.askFollowUpQuestions()
      }, 500)
    },
    setLoading(val) {
      if (val) {
        setTimeout(() => {
          this.pushMessage('system', 'loader')
        }, 500)
        this.loader = true
      } else {
        let index = this.messages.findIndex(obj => obj.content === "loader");

        while (index !== -1) {
          this.messages.splice(index, 1);
          index = this.messages.findIndex(obj => obj.content === "loader");
        }

        this.loader = false
      }
    },

  }
}

</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .fallowUpQuestionArea {
    width: 100% !important;
    height: 550px !important;
  }
}

.textMessages {
  padding: 10px;
  text-align: right;
  height: auto;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 20pt;
}

.textMessagesNotMe {
  max-width: 300px;
  border-radius: 0px 10px 10px 10px;
  background-color: #78a5ff;
  color: white;
}

.textMessagesMe {
  max-width: 300px;
  border-radius: 10px 0px 10px 10px;
  background-color: #d1dcff;
  color: #574596;
}

.messagesArea {
  height: 250px;
  overflow-y: auto;
}

.fallowUpQuestionArea {
  width: 50%;
  height: 80%;
  background: white;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
}

.headerBanner {
  height: 70px;
  background: #47c8bd;
}

.nameGPT {
  color: white;
  font-weight: bolder;
  margin-right: 10px;
}

.GPTstatus {
  color: white;
  font-size: 12px;
  margin-right: 10px;
}

.actionArea {
  height: 70px;
}
</style>