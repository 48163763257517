<template>
  <div class="fill-height">
    <v-row class="fill-height" v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="12" lg="2">
        <div class="fill-height">
          <ConversationList/>
        </div>
      </v-col>
      <v-col cols="12" lg="10">
        <MessangerChatGPT/>
      </v-col>
    </v-row>
    <div class="" v-else>
      <v-window v-model="window">
        <v-window-item :value="0">
          <ConversationList/>
        </v-window-item>
        <v-window-item :value="1">
          <MessangerChatGPT/>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import DiagnosisTemplate from "@/components/ChatGpt/DiagnosisTemplate";
import MessangerChatGPT from "@/components/ChatGpt/MessangerChatGPT";
import {mapGetters} from "vuex";
import moment from "moment-jalaali";
import ConversationList from "@/components/ChatGpt/ConversationList";

export default {
  components: {ConversationList, MessangerChatGPT, DiagnosisTemplate},

  data() {
    return {
      window: 0,
    }
  },

  methods: {},

  mounted() {
    if (this.$route.query.start == 'new') {
      this.window = 1
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query.conversation_id) {
        this.window = 1
      } else {
        this.window = 0
      }
    },
    '$route.query.start'() {
      if (this.$route.query.start == 'new') {
        this.window = 1
      }
    }
  }
}
</script>

<style scoped>

</style>