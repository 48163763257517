<template>
  <div class="fill-height">
    <div class="ConversationList  fill-height">
      <div class="conversationTitleBox  text-center pa-3 primary--text font-weight-bold mt-2"
           style="">
        تاریخچه
      </div>
      <div class="historyBox mt-2">
        <div class="historyItem " :class="$route.query.conversation_id == conversation.id && 'activeConversation'"
             @click="$route.query.conversation_id != conversation.id && selectConversation(conversation.id)"
             v-for="(conversation , i) in conversations" :key="i">
          <div class="">
            <v-icon large color="primary">mdi-history</v-icon>
          </div>
          <div class="d-flex justify-center align-center flex-column mr-2">
            {{ conversation.start_at | jalaliFormater }}
            -
            {{ conversation.start_at | gorgianFormater }}
          </div>
        </div>
        <div class="text-center">
          <v-btn color="primary" @click="startNewConversation" text>
            گفتگوی جدید
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-3" v-if="pagination.total != 1">
        <v-pagination v-model="pagination.page" :length="pagination.total"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    })
  },
  data() {
    return {
      conversations: [],
      pagination: {
        page: 1,
        total: 1,
      }
    }
  },
  methods: {
    startNewConversation() {
      this.$router.push({name: 'chatGpt', query: {start: 'new'}})

      this.$store.dispatch('setGPTResponse', {
        response: null,
        fallow_up: null
      })
    },
    getConversations() {
      this.$emitEvent('setLoading', true)
      this.$store.dispatch('getGPTConversations', {
        userId: this.profile.id,
        page: this.pagination.page
      }).then((resp) => {
        this.conversations = resp.data.data.conversations
        this.pagination = {
          total: resp.data.data.pagination.total_pages,
          page: resp.data.data.pagination.current_page
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    selectConversation(id) {
      this.$router.push({name: 'chatGpt', query: {conversation_id: id,}})
      this.getConversationMessages(id)
    },
    getConversationMessages(conversation_id) {
      this.$emitEvent('setLoading', true)
      this.$store.dispatch('getGPTConversationMessage', {
        userId: this.profile.id,
        conversation_id: conversation_id,
      }).then((resp) => {
        let messages = resp.data.data.messages
        let fallow_up_questions_index = messages.findIndex((item) => {
          return item.instruction_id == 3
        })
        let response_index = messages.findIndex((item) => {
          return item.instruction_id == 4
        })
        this.$store.dispatch('setGPTResponse', {
          response: messages[response_index],
          fallow_up: messages[fallow_up_questions_index]
        })
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    }
  },
  filters: {
    gorgianFormater(value) {
      if (value) {
        return moment(value).format('YYYY/MM/DD')
      }
    },
    jalaliFormater(value) {
      if (value) {
        return moment(value).format('jYYYY/jMM/jDD')
      }
    },
  },
  mounted() {
    this.getConversations()

    this.$onEvent('getConversations', () => {
      this.getConversations()
    })

    if (this.$route.query.conversation_id) {
      this.selectConversation(this.$route.query.conversation_id)
    }
  },
  watch: {
    'pagination.page'() {
      this.getConversations()
    }
  }
}
</script>

<style scoped>
.ConversationList {
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 10px 5px 10px;
  background-color: white;
  border-right: 2px solid transparent;
  height: 750px;
  position: sticky;
  top: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 2px 8px -6px rgba(0, 0, 0, 0.5);
}

.conversationTitleBox {
  border-bottom: 1px solid #3CBE8B;
}

.historyItem {
  display: flex;
  font-size: 11px;
  padding: 10px;
  border-right: 1px solid transparent;
}

.historyItem {
  margin-top: 12px;
}

.activeConversation {
  background: rgba(0, 230, 180, .1);
  border-color: #47c8bd;
  transition: all 0.3s;
  box-shadow: 0 6px 12px -10px rgba(0, 0, 0, .3);

}

.historyBox {
  height: 620px;
  overflow: auto;
}
</style>