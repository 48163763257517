<template>
  <div class="fill-height">
    <div class="diagnosisArea">
      <div class="imageDiagnosis " style="">
        <lottie-animation
            ref="anim"
            :animationData="require('../../assets/animation/animation_lk80o8lc.json')"
            :loop="true"
            :autoPlay="true"
            style="width: 250px;margin: 0px auto"
        />
<!--                <img src="../../assets/therapy.png" alt="">-->
      </div>
      <div class="diagnosisItem mt-3">
        <p>
          <!--          <b></b>-->
          {{ diagnosis.advice }}
        </p>
      </div>
      <div class="diagnosisItem mt-3">
        <p>
          <span class="badge mx-1 badge-secondary" v-for="(label ,  i ) in diagnosis.labels" :key="i">{{ label }}</span>
        </p>
      </div>

      <!--      <div class="diagnosisItem mt-3">-->
      <!--        <p>-->
      <!--          <b>تعداد حدودی جلسات مورد نیاز:</b>-->
      <!--          {{ diagnosis.suggested_number_suggestion }}-->
      <!--        </p>-->
      <!--      </div>-->
      <!--      <div class="diagnosisItem mt-3">-->
      <!--        <p>-->
      <!--          <b>برنامه درمانی :</b>-->
      <!--          {{ diagnosis.treatment_plan }}-->
      <!--        </p>-->
      <!--      </div>-->
      <div class="diagnosisItem mt-3">
        <p>
          <b>رویکرد :</b>
        </p>
        <ul>
          <li v-for="(goal ,  i ) in diagnosis.goals" class="mb-3" :key="i">
            <p>
              <b>
                {{ goal.goal }}
              </b>
            </p>
            <div style="margin-right: 10px;margin-top: -15px;line-height: 25pt">
              {{ goal.description }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="shareArea text-center my-5">
<!--      <a target="_blank" href="https://api.whatsapp.com/send?text=https://simiaroom.com/mental-ai-tool">-->
<!--        <img src="../../assets/icon/whats.png" class="shareIcon" alt="">-->
<!--      </a>-->
<!--      <a target="_blank" class="mx-2" href="https://t.me/share/url?text=https://simiaroom.com/mental-ai-tool">-->
<!--        <img src="../../assets/icon/telegram.png" class="shareIcon" alt="">-->
<!--      </a>-->
<!--      <a target="_blank" href="https://twitter.com/intent/tweet?text=https://simiaroom.com/mental-ai-tool">-->
<!--        <img src="../../assets/icon/673524.png" class="shareIcon" alt="">-->
<!--      </a>-->
    </div>

  </div>
</template>

<script>
/*eslint-disable*/
import LottieAnimation from 'lottie-web-vue'

export default {
  props: {
    diagnosis: Object,
  },
  components: {
    LottieAnimation
  },
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .diagnosisArea {
    width: 90% !important;
  }

  .shareArea {
    width: 90% !important;
  }


  .collageBanner {
    background: none !important;
    border-top: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;

  }

  .contentCollegeBanner {
    width: 90% !important;
    margin: 0px auto !important;
    text-align: center;
  }

  .actionCollage {
    margin: 0px auto !important;
  }

  .titleCollege {
    font-size: 20px !important;
  }

  .contentCollegeBanner p {
    font-size: 18px !important;
  }
}

.shareIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.badge-secondary {
  background-color: #39B787;
}

.diagnosisItem {
  line-height: 22pt;
}

.diagnosisArea {
  /*height: 100%;*/

  border: 1px solid rgba(0, 0, 0, .08);
  /*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;*/

  box-shadow: 0 40px 40px -40px rgba(0, 0, 0, .2),
  inset 0 10px 50px -30px rgba(0, 0, 0, .1);

  /*box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;*/
  width: 50%;
  margin: 0px auto;
  background-color: white;
  padding:30px;
  border-radius: 10px;
  margin-top: 100px;
}


.shareArea {
  height: 100%;
  width: 65%;
  margin: 50px auto 0px auto;
}

.imageDiagnosis {
  text-align: center;
  margin-top: -140px;
}

.imageDiagnosis img {
  width: 300px;
}

.collageBanner {
  /*box-shadow: 0px 50px 50px -50px rgba(0, 0, 0, 0.1 );*/
  /*border: 1px solid rgba(0, 0, 0, 0.3);*/
  /*background: url("../../assets/image/index/daneshjoie.jpg") no-repeat;*/
  background-size: contain;
  height: 350px;
  /*margin-top: 100px;*/
  margin: 100px 20px;
}

.contentCollegeBanner {
  width: 50%;
  margin: 0px 10%;
}

.titleCollege {
  font-size: 35px;
  color: #39B787;
  margin-bottom: 10px;
}

.contentCollegeBanner p {
  font-size: 19px;
  margin-bottom: 20px;
}

.actionCollage {
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #39B787;
  width: 280px;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  /*margin-right: 18%;*/
}
</style>